<template>
  <div>
    <HubDropdown
      id="userDropdown"
      class="text-sm"
      dropdown-placement="top"
      screen-reader-text="Open User Menu"
      :items="userDropdownItems"
      v-bind="$attrs"
      :ui="{ height: 'max-h-full' }"
    >
      <span class="sr-only">Open User Menu</span>
      <UserAvatar
        class="w-full"
        :name="sessionData?.user?.name || undefined"
        :image="sessionData?.user?.image || undefined"
        :email="sessionData?.user?.email || undefined"
        :show-name="showName"
      />
    </HubDropdown>

    <LazyAccountDetailsModal v-model="showAccountModal" />
    <LazyFeedbackModal v-model="showFeedbackModal" />
  </div>
</template>

<script setup lang="ts">
import { type HubSession, type HubUserOrganisationPermission } from '~/types'
import { type HubDropdownItem } from '~/types/component'

const organisationStore = useOrganisationStore()
const userStore = useUserStore()
const releaseStore = useReleaseStore()

await userStore.fetchCurrentUser()
const { releases, latestRelease } = storeToRefs(releaseStore)
const { currentUser } = storeToRefs(userStore)
const organisations = await organisationStore.fetchOrganisations()

const isAdmin: Ref<boolean> = ref(false)
const rootOrgId: Ref<number | undefined> = ref()

if (organisations.length) {
  rootOrgId.value = organisations[0].rootOrganisationNodeId
  const { data: userDetails } = (await useHubFetch<HubUserOrganisationPermission>(
    `/api/v4/organisations/${rootOrgId.value}/users/${currentUser.value?.id}`
  )) as { data: Ref<HubUserOrganisationPermission> }
  isAdmin.value = userStore.checkIsAdmin(userDetails.value, rootOrgId.value)
}

const viewport = useViewport()
const { data: sessionData, signOut, getSession } = useAuth()
const { t } = useI18n()

withDefaults(
  defineProps<{
    showName?: boolean
  }>(),
  {
    showName: false
  }
)

const showAccountModal: Ref<boolean> = ref(false)
const showFeedbackModal: Ref<boolean> = ref(false)

const userDropdownItems: ComputedRef<Array<Array<HubDropdownItem>>> = computed(() => [
  [
    {
      label: sessionData?.value?.user?.name || sessionData?.value?.user?.email || '',
      slot: 'user',
      class: 'select-none cursor-default hover:bg-white hover:text-grey-blue'
    }
  ],
  [
    {
      label: t('hub.account.myDetails'),
      icon: 'i-mdi-account-outline',
      hidden: !checkPermissions({ permissions: 'Users.Self.Read' }),
      click: () => { showAccountModal.value = true }
    },
    {
      label: t('hub.account.accountAdmin'),
      icon: 'i-mdi-cog-outline',
      hidden: !(
        checkPermissions({ permissions: 'Users.Read' })
        && isAdmin.value
        && rootOrgId.value && viewport.isGreaterOrEquals('lg')
      ),
      click: () => navigateTo(`/account/${rootOrgId.value}/users`)
    },
    {
      label: t('hub.account.faq'),
      icon: 'i-mdi-help-circle-outline',
      trailingIcon: 'i-mdi-open-in-new',
      trailingIconColor: 'dark-grey',
      click: () =>
        navigateTo('https://support.trurating.com/support/solutions/12000005529', {
          open: {
            target: '_blank'
          },
          external: true
        })
    },
    {
      label: t('hub.account.provideFeedback'),
      icon: 'i-mdi-megaphone-outline',
      click: () => { showFeedbackModal.value = true }
    },
    {
      label: t('hub.account.requestSupport'),
      icon: 'i-mdi-comment-help-outline',
      trailingIcon: 'i-mdi-open-in-new',
      trailingIconColor: 'dark-grey',
      click: () =>
        navigateTo('https://trurating.com/contact-us/help-and-support/', {
          open: {
            target: '_blank'
          },
          external: true
        })
    },
    {
      label: t('releases.title'),
      icon: 'i-mdi-list-box-outline',
      hidden: !checkPermissions({ permissions: 'Releases.Read' }) || !releases.value || !releases.value.length,
      click: () => navigateTo('/releases')
    }
  ],
  [
    {
      label: 'Language',
      slot: 'language'
    }
  ],
  [
    {
      label: t('hub.account.logout'),
      icon: 'i-mdi-logout',
      color: 'trublue',
      click: signUserOut
    }
  ],
  [
    {
      label: t('releaseNumber') + ': ' + latestRelease.value?.releaseNumber,
      trailingIcon: 'i-mdi-content-copy',
      trailingIconColor: 'dark-gray',
      class: 'text-gray-400 text-xs font-medium',
      click: () => navigator.clipboard.writeText(latestRelease.value?.releaseNumber || 'unknown')
    }
  ]
])

async function signUserOut() {
  const s = (await getSession()) as HubSession
  await signOut({ callbackUrl: s.signOutUrl })
}
</script>
